import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ThemeSwitcherStyled = styled.div`
  position: absolute;
  top: 60px;
  right: 60px;
  cursor: pointer;
  display: none;
  z-index: 100500;
  @media (min-width: 771px) {
    display: block;
  }
  .theme-switcher__button {
    width: 80px;
    height: 80px;
    background-color: transparent;
    transition: all 0.2s ease-in;
    outline: none;
    cursor: pointer;
  }
  .moon {
    width: 80px;
    height: 80px;
    background-color: #ebf3fe;
    border-radius: 50%;
    border: black solid 4px;
    box-shadow: inset -10px 0px 0px #d8e8f7, 
      inset 10px 0px 0px #ffffff, 
      inset -15px 0px 0px 5px #e2eefa, 
      0px 0px 0px 10px rgba(255, 255, 255, 0.05),
      0px 0px 0px 20px rgba(255, 255, 255, 0.025), 
      0px 0px 0px 40px rgba(255, 255, 255, 0.012);
  }
  .sun {
    width: 80px;
    height: 80px;
    background-color: #ffdb01;
    border-radius: 50%;
    border: black solid 4px;
    box-shadow: inset -10px 0px 0px #ff8603, 
      inset 10px 0px 0px #ffffff, 
      inset -15px 0px 0px 5px #ffd201, 
      0px 0px 0px 10px rgba(255, 210, 1, 0.2),
      0px 0px 0px 20px rgba(255, 210, 1, 0.1), 
      0px 0px 0px 40px rgba(255, 210, 1, 0.05);
  }
  
  .face {
    position: absolute;
    left: 30px;
    width: 20px;
  }
  .eye {
    width: 5px;
    height: 5px;
    display: block;
    animation-name: eye-blink;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
    position: relative;
    overflow: hidden;
  }
  .eye--left {
     position: absolute;
     left: 0;
  }
  .eye--right {
    position: absolute;
    right: 0;
  }
  
  .eye-in {
    content: "";
    width: 5px;
    height: 5px;
    background-color: #151843;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    transform: scale(1, 1) !important;
    border: none;
  }
`;

export class ThemeSwitcher extends Component {
  render() {
    const { onToggleTheme, theme } = this.props;
    const isBlack = theme === 'black';
    return <ThemeSwitcherStyled>
      <button className={`${ isBlack ? 'moon' : 'sun' } theme-switcher__button button-reset`}
              onClick={onToggleTheme}>
        {/*<div className="face">*/}
          {/*<div className="eye eye--left">*/}
            {/*<div className="eye-in"/>*/}
          {/*</div>*/}
          {/*<div className="mouth"/>*/}
          {/*<div className="eye eye--right">*/}
            {/*<div className="eye-in"/>*/}
          {/*</div>*/}
        {/*</div>*/}
      </button>
    </ThemeSwitcherStyled>;
  }
}

ThemeSwitcher.propTypes = {};
ThemeSwitcher.defaultProps = {
  theme: 'black',
  onToggleTheme: () => {}
};

export default ThemeSwitcher;
