import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BaseLayout from '../../layouts/BaseLayout';
import Container from '../../layouts/Container';
import RewardsList from '../../components/RewardsList';
import { HackathonsList } from '../../components/Hackathons';
import { list } from '../../components/Hackathons/data';

class RewardsPage extends Component {
  render() {
    return (
        <BaseLayout>
          <section className="section">
            <Container>
              <div className='header'>
                <h2 className='f1 lh-title'>Достижения (2018 - ∞)</h2>
                <span className='header__subtitle'><i>За этот год я...</i></span>
              </div>
            </Container>
            <Container>
              <div className='header'>
                <h2 className='f2 lh-title'>Открыл для себя "Мир Хакатонов"</h2>
              </div>
            </Container>
            <HackathonsList list={list}/>
          </section>
        </BaseLayout>
    );
  }
}

RewardsPage.propTypes = {};
RewardsPage.defaultProps = {};

export default RewardsPage;
