import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { list } from './data';

const SkillsListStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0 -25px;
  margin-bottom: -25px;
`;

const SkillsItem = styled.div`
  padding: 25px;
  padding-top: 0;
  text-align: center;
  img{
    width: 100%;
    height: auto;
  }
  .skill-item__title {
      display: none;
  }
  @media (min-width: 576px) {
    .skill-item__title {
        display: block;
    }
  }
`;

export class SkillsList extends Component {
  render() {
    const { list } = this.props;
    return (
        <Fragment>
          <div className='header'>
            <h2 className='f2 lh-title'>Мой стек технологий ❤️</h2>
          </div>
          <SkillsListStyled>
            {list.map( item =>
                <SkillsItem className='w-50 w-25-m w-20-l' key={item.id}>
                  <img src={item.image} alt={item.alt}/>
                  <h3 className="skill-item__title">{item.title}</h3>
                </SkillsItem>
            )}
          </SkillsListStyled>
        </Fragment>
    );
  }
}

SkillsList.propTypes = {};
SkillsList.defaultProps = {
  list: list
};

export default SkillsList;
