import { teams } from '../../Teams/data';

export const list = [
  {
    id: 1,
    title: 'Hackathon по технологиям в рекламе и маркетинге',
    description: '',
    preview: '/images/adHack.jpg',
    detail: '/images/adHack_adlaunch.jpg',
    textPreview: 'Участники команды "ADLAUNCH" разработали систему для автоматизации создания и размещения рекламы на различных площадках.',
    site: 'http://adhack.muzis.ru/',
    dateStart: '26',
    dateEnd: '27 августа',
    year: '2017',
    place: 1, // number,
    location: 'г. Санкт-Петербург',
    team: teams["ADLAUNCH"],
    project: {
      title: "Решение для автоматизации создания рекламы в социальных сетях",
      description: ""
    },
    size: false,
    tags: ["adhack", "spb"],
    sort: 1,
    column: 1
  },
  {
    id: 2,
    title: 'Хакатон PSB Samara Battle',
    description: '',
    preview: '/images/psbattle_preview.png',
    detail: '/images/psbattle_innovation.jpg',
    textPreview: 'Участники команды "innovation" предложили вариант использования мобильного телефона в качестве платежного терминала.',
    site: 'https://psbattle.ru/',
    dateStart: '30 сентября',
    dateEnd: '1 октября',
    year: '2017',
    place: 1, // number,
    price: 250000,
    location: 'г. Самара',
    team: teams["INNOVATION"],
    project: {
      title: "Мобильный платежный терминал",
      description: ""
    },
    size: false,
    tags: ["psb"],
    sort: 1,
    column: 1
  },
  {
    id: 3,
    title: 'ТИЛТЕХ МЕДХАК',
    description: '',
    preview: '/images/tilteh_moscow.png',
    detail: '/images/med_moscow_innnovation.jpg',
    // textPreview: 'Участники команды "innovation".',
    site: 'http://tealtechmedhack.sci-guide.com/',
    dateStart: '10',
    dateEnd: '12 ноября',
    year: '2017',
    place: 1, // number,
    location: 'г. Москва',
    team: teams["INNOVATION"],
    project: {
      title: "Симптомчекер", // TODO найти реальной название
      description: ""
    },
    size: false,
    tags: ["moscow", "tilteh", "med"],
    sort: 3,
    column: 1
  },
  {
    id: 4,
    title: 'ТИЛТЕХ МЕДХАК',
    description: '',
    preview: '/images/tilteh_spb.png',
    detail: '/images/med_spb_panacea.jpg',
    textPreview: 'Проект «Panacea.Cloud», представленный на хакатоне в Санкт-Петербурге по цифровой медицине, не только одержал победу, но и заинтересовал крупнейший фармакологический концерн «Bayer»',
    site: 'http://tealtechmedhack.sci-guide.com/',
    dateStart: '17',
    dateEnd: '19 ноября',
    year: '2017',
    place: 3, // number,
    location: 'г. Санкт-Петербург',
    team: teams["PANACEA"],
    project: {
      title: "«Panacea.Cloud» - Клиника в облаке",
      description: ""
    },
    size: false,
    tags: ["piter", "tilteh", "med"],
    sort: 9,
    column: 2
  },
  {
    id: 5,
    title: ' HACKUNIVERSITY 2018',
    description: '',
    preview: '/images/hakaton_universitet.jpg',
    detail: '/images/hakaton_universitet_innovation.jpg',
    // textPreview: 'Проект «Panacea.Cloud», представленный на хакатоне в Санкт-Петербурге по цифровой медицине, не только одержал победу, но и заинтересовал крупнейший фармакологический концерн «Bayer»',
    site: 'http://hackuniversity.ru/',
    dateStart: '13',
    dateEnd: '15 апреля',
    year: '2018',
    place: 2, // number,
    location: 'г. Санкт-Петербург',
    team: teams["INNOVATION"],
    project: {
      title: "Сервис для \"погружения в прошлое\"",
      description: ""
    },
    size: false,
    tags: ["piter", "UNIVERSITY", "megahack"],
    sort: 7,
    column: 2
  },
  {
    id: 6,
    title: 'ActumDigitalHack',
    description: '',
    preview: '/images/actum.jpg',
    detail: '/images/actum_facepalm.jpg',
    // textPreview: 'Проект «Panacea.Cloud», представленный на хакатоне в Санкт-Петербурге по цифровой медицине, не только одержал победу, но и заинтересовал крупнейший фармакологический концерн «Bayer»',
    site: 'https://actum.online/HTEventCard?id=20',
    dateStart: '18',
    dateEnd: '20 мая',
    year: '2018',
    place: 2, // number,
    location: 'г. Санкт-Петербург',
    team: teams["FACEPALM"],
    project: {
      title: "Hackathons.Space",
      description: "Первая в России платформа для хакатонов, объединяющая талантливых участников и талантливых организаторов."
    },
    size: false,
    tags: ["piter", "facepalm", "actum"],
    sort: 6,
    column: 2
  },
  {
    id: 7,
    title: 'HACKING MAN',
    description: '',
    preview: '/images/evotor.jpg',
    detail: '/images/evotor_innovation.jpg',
    site: 'https://hack.evotor.ru/',
    dateStart: '2',
    dateEnd: '3 декабря',
    year: '2017',
    place: null, // number,
    location: 'г. Москва',
    team: teams["INNOVATION"],
    project: {
      title: "Решение для персонализации музыки в развлекательных заведениях",
      // description: "Первая в России платформа для хакатонов, объединяющая талантливых участников и талантливых организаторов."
    },
    size: false,
    tags: ["moscow", "innovation", "evotor"],
    sort: 7,
    column: 3
  },
  {
    id: 8,
    title: 'HACKATHON 2025',
    description: '',
    preview: '/images/expo2025.jpg',
    detail: '/images/expo_your_mentor.jpg',
    site: 'https://hackathon2025.ru/',
    dateStart: '27',
    dateEnd: '28 января',
    year: '2018',
    place: null, // number,
    location: 'г. Москва',
    team: teams["YOURMENTOR"],
    project: {
      title: "Твой наставник",
      description: "Платформа для поиска ментора на все случаи жизни"
    },
    size: false,
    tags: ["moscow", "expo2025", "2025"],
    sort: 8,
    column: 3
  }
];