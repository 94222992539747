import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaseLayout from '../../layouts/BaseLayout';
import Container from '../../layouts/Container';
import { Redirect } from 'react-router-dom';
import { ProjectsDetail } from '../../components/Projects';
import { list } from '../../components/Projects/data';

class ProjectsDetailPage extends Component {
  render() {
    const { id } = this.props;
    const item = list.find(item => item.id === +id);
    if (item)
      return (
          <BaseLayout>
            <section className="section">
              {/*<Container>*/}
              <ProjectsDetail item={item}/>
              {/*</Container>*/}
            </section>
          </BaseLayout>
      );
    return <Redirect to='/404'/>;
  }
}

ProjectsDetailPage.propTypes = {};
ProjectsDetailPage.defaultProps = {};

export default ProjectsDetailPage;
