export const teams = {
  GAGARIN: {
    name: "GAGARIN ONLINE"
  },
  ADLAUNCH: {
    name: "ADLAUNCH",
  },
  INNOVATION: {
    name: "innovation"
  },
  PANACEA: {
    name: "Panacea.Cloud",
    site: "https://panacea.cloud/"
  },
  FACEPALM: {
    name: "FacePalm"
  },
  YOURMENTOR: {
    name: "Твой наставник"
  }
};