import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import ProjectsListPage from './ProjectsList';
import ProjectsDetailPage from './ProjectsDetail';

class ProjectsPage extends Component {
  render() {
    return (
        <Switch>
          <Route path='/projects/:id'
                 component={({ match }) => <ProjectsDetailPage id={match.params.id}/>}/>
          <Route component={ProjectsListPage}/>
        </Switch>
    );
  }
}

ProjectsPage.propTypes = {};
ProjectsPage.defaultProps = {};

export default ProjectsPage;
