import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from "styled-components";
import { getPlaceIcon } from '../../utils';

const CardAction = styled.div`
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  opacity: 0;
  padding: 15px;
  font-size: 1rem;
  background-color: var(--main-brand-color);
  transition: all 0.5s ease-in-out;
`;

const Card = styled.div`
  overflow: hidden;
  display: block;
  position: relative;
  background: url(${({ preview }) => preview}) no-repeat center center fixed; 
  background-color: var(--medium-color);
  background-size: cover;
  margin-bottom: 15px;
  @media(min-width: 864px){
    margin: 15px;
    margin-bottom: 30px;
  }
  &:after{
    display: flex;
    align-items: center;
    justify-content: center;
    content: 'Контент на модерации';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--medium-color);
    color: var(--main-text-color);
    opacity: ${({ data: { disabled = false } }) => disabled ? 1 : 0}
  }
  &:hover{
    ${CardAction}{
      left: 0;
      opacity: 1;
    }
  }
`;

const LinkCard = Card.withComponent(NavLink);

export class ProjectsItem extends Component {
  render() {
    const { data } = this.props;
    if (this.props.data.disabled)
      return (<Card className="grow db no-underline black"
                    {...this.props}>
        <img className="db w-100"
             src={data.preview}
             alt={data.title}/>
      </Card>);
    return (<LinkCard to={`/projects/${data.id}`}
                      className="grow db no-underline black" {...this.props}>
      <img className="db w-100"
           src={data.preview}
           alt={data.title}/>
      <CardAction>Подробнее</CardAction>
    </LinkCard>);

  }
}

ProjectsItem.propTypes = {};
ProjectsItem.defaultProps = {
  data: null
};

export default ProjectsItem;
