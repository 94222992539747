import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '../Container';
import Contacts from '../../components/Contacts';

const FooterStyled = styled.footer`
  flex: 0 0 auto;
  padding-top: 40px;
  padding-bottom: 10px;
  .cooperight{
    font-size: 0.9em;
  }
`;

class Footer extends Component {
  render() {
    return (
        <FooterStyled>
          <Container>
            <Contacts/>
            <span className='cooperight'>© 2019 Андрей Муллагалиев</span>
          </Container>
        </FooterStyled>
    );
  }
}

Footer.propTypes = {};
Footer.defaultProps = {};

export default Footer;
