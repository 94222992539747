import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import './css/normalize.css';
import 'tachyons/css/tachyons.min.css';
import './css/style.css';

const Index = () => (
    <Router>
      <App/>
    </Router>
);

render(<Index/>, document.getElementById("app"));
