import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import HackathonsListPage from './HackathonsList';
import HackathonsDetailPage from './HackathonsDetail';

class HackathonsPage extends Component {
  render() {
    return (
        <Switch>
          <Route path='/hackathons/:id'
                 component={({ match }) => <HackathonsDetailPage id={match.params.id}/>}/>
          <Route component={HackathonsListPage}/>
        </Switch>
    );
  }
}

HackathonsPage.propTypes = {};
HackathonsPage.defaultProps = {};

export default HackathonsPage;
