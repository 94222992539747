import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import BaseLayout from '../../layouts/BaseLayout';
import Container from '../../layouts/Container';
import { SkillsList } from '../../components/SkillsList';

class AboutPage extends Component {
  render() {
    return (
        <BaseLayout>
          <section className="section">
            <article>
              <Container>
                <h2 className="f1 lh-title">Привет 👋!</h2>
                <p className="measure lh-copy tj">
                  Меня зовут <b>Андрей Муллагалиев</b>. Мне <b>23&nbsp;года</b>, живу&nbsp;в&nbsp;
                  <span className='city-name text--old'>г.&nbsp;Тюмень</span>
                  <span> &rarr; </span>
                  <span className='city-name text--old'>г.&nbsp;Санкт-Петербург</span>
                  <span> &rarr; </span>
                  <b className='city-name'>г.&nbsp;Москва</b>, работаю в&nbsp;
                  <span className='company-name text--old'>GAGARIN ONLINE</span>
                  <span> &rarr; </span>
                  <span className='company-name text--old'>Fora&nbsp;Soft</span>
                  <span> &rarr; </span>
                  <span className='company-name text--old'>Фрилансе</span>
                  <span> &rarr; </span>
                  <span className='company-name text--old'>goTRG</span>
                  <span> &rarr; </span>
                  <b className='company-name company-name--sberbank'>Сбербанк</b>.
                  Имею высшее образование по специальности <b>математик-программист</b>, но
                  занимаюсь преимущественно <b>Front-end разработкой</b>.
                  Разработкой занимаюсь уже около 6&nbsp;лет, 3&nbsp;из&nbsp;которых
                  коммерческий опыт в&nbsp;web.
                </p>
                <h3 className='f3 lh-title'>В свободное время</h3>
                <ul>
                  <li>
                    Побеждаю в хакатонах <NavLink
                      to='/rewards/#hackathons'>(6 побед)</NavLink>
                  </li>
                  <li>
                    Разрабатываю&nbsp;
                    <a href="https://about.hackathons.space/"
                       target='_blank'
                       rel="nofollow noopener">
                      стартап
                    </a>
                  </li>
                  <li>
                    <a href="https://vk.com/java_mentor?w=product-143173387_2588917%2Fquery"
                       target='_blank'
                       rel="nofollow noopener">
                      Учу Front-end'у
                    </a>
                  </li>
                </ul>
                <p className="measure lh-copy tj">
                  <b>Открыт для офферов</b> на вакансии Front-end, Full-stack, JS developer и Team Lead.
                  Резюме высылаю по просьбе. Контакты можно найти <a href="#contacts">ниже</a>
                </p>
                <SkillsList/>
              </Container>
            </article>
          </section>
        </BaseLayout>
    );
  }
}

AboutPage.propTypes = {};
AboutPage.defaultProps = {};

export default AboutPage;
