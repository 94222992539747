import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { Page404 } from './pages/ErrorPage';
import AboutPage from './pages/About';
import RewardsPage from './pages/Rewards';
import BlogPage from './pages/Blog';
import ProjectsPage from './pages/Projects';
import HackathonsPage from './pages/Hackathons';

class App extends Component {
  render() {
    return (
        <Switch>
          <Route path="/rewards" exact component={RewardsPage}/>
          <Route path="/hackathons*" exact component={HackathonsPage}/>
          <Route path="/blog" exact component={BlogPage}/>
          <Route path="/projects*" exact component={ProjectsPage}/>
          <Route path="/" exact component={AboutPage}/>
          <Route path="/404" component={Page404}/>
          <Route component={Page404}/>
        </Switch>
    );
  }
}

App.propTypes = {};
App.defaultProps = {};

export default App;
