import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getPlaceIcon } from '../../utils';

const PreviewContainer = styled.div`
  background: url(${({ preview }) => preview}) no-repeat center center fixed; 
  background-size: cover;
`;

export class HackathonsDetail extends Component {
  render() {
    const { item } = this.props;
    return (
        <article data-name="article-full-bleed-background">
          <PreviewContainer className="cf" preview={item.detail ? item.detail : item.preview}>
            <div className="fl pa3 pa4-ns bg-black measure-narrow f3 times">
              <header className="bb pv4">
                <h2 className="f2 fw7 ttu tracked lh-title mt0 mb3 avenir">{item.title} {item.place ? getPlaceIcon(item.place) : ''}</h2>
                <h3 className="f3 fw4 i lh-title mt0">
                  <i>{item.location} ({item.year} год)</i></h3>
              </header>
              <section className="pt5 pb4">
                <p className="lh-copy measure f4 mt0">
                  {item.textPreview}
                </p>
              </section>
            </div>
          </PreviewContainer>
          <PreviewContainer className="vh-100 dt w-100 tc bg-dark-gray white cover"
                            preview={item.detail ? item.detail : item.preview}>
            <div className="dtc v-mid">
              <header className="white-70">
                <h3 className="f6 fw1 ttu tracked mb2 lh-title">{item.location}</h3>
                <h3 className="f6 fw1 lh-title">{item.dateStart} {item.dateEnd ? ' - ' + item.dateEnd : null}</h3>
              </header>
              {item.project && item.project.title &&
              <h1 className="f1 f-headline-l fw7 white-60">{item.project.title}</h1>}
              <blockquote className="ph0 mh0 measure f4 lh-copy center">
                {item.project && item.project.description &&
                <p className="fw1 white-70">{item.project.description}</p>}
                {item.team && item.team.name &&
                <cite className="f6 ttu tracked fs-normal"><b>Команда «{item.team.name}»</b></cite>}
              </blockquote>
            </div>
          </PreviewContainer>
          <div className="center measure-wide f5 pv5 lh-copy ph2">
            <h1 className="f1 lh-title">Как это было?</h1>
            <p>
              <i>Скоро узнаем...</i>
            </p>
          </div>
        </article>
    );
  }
}

HackathonsDetail.propTypes = {};
HackathonsDetail.defaultProps = {
  item: {}
};

export default HackathonsDetail;
