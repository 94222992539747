import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaseLayout from '../../layouts/BaseLayout';
import Container from '../../layouts/Container';
import { HackathonsList } from '../../components/Hackathons';

class HackathonsListPage extends Component {
  render() {
    return (
        <BaseLayout>
          <section className="section">
            <Container>
              <div className='header'>
                <h2>Список хакатонов</h2>
                <span className='header__subtitle'><i>Победы и поражения</i></span>
              </div>
              <HackathonsList/>
            </Container>
          </section>
        </BaseLayout>
    );
  }
}

HackathonsListPage.propTypes = {};
HackathonsListPage.defaultProps = {};

export default HackathonsListPage;
