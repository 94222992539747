import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ContainerStyled = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 8px;
  padding-right: 8px;
  @media (min-width: 320px) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 576px) {
    width: 592px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 768px) {
    width: 784px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 991px) {
    width: 1007px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 1200px) {
    width: 1216px;
    margin-left: auto;
    margin-right: auto;
  }
`;

class Container extends Component {
  render() {
    return (
        <ContainerStyled>
          {this.props.children}
        </ContainerStyled>
    );
  }
}

Container.propTypes = {};
Container.defaultProps = {};

export default Container;
