import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ProjectsItem from './ProjectsItem';

const ProjectsListStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  @media(min-width: 864px){
    margin: 0 -15px;
  }
`;

export class ProjectsList extends Component {
  render() {
    const list = this.props.list
        .sort((a, b) => a.sort > b.sort);
    return (
        <ProjectsListStyled id="hackathons" className="cf">
          <div className="fl w-100 w-100-m w-50-l">
            {
              list.filter(item => item.column === 1).map(item =>
                  <ProjectsItem data={item} key={item.id}/>
              )
            }
          </div>
          <div className="fl w-100 w-100-m w-50-l">
            {
              list.filter(item => item.column === 2).map(item =>
                  <ProjectsItem data={item} key={item.id}/>
              )
            }
          </div>
          <div className="fl w-100 w-50-m w-25-l">
            {
              list.filter(item => item.column === 3).map(item =>
                  <ProjectsItem data={item} key={item.id}/>
              )
            }
          </div>
        </ProjectsListStyled>
    );
  }
}

ProjectsList.propTypes = {};
ProjectsList.defaultProps = {
  list: []
};

export default ProjectsList;
