export const list = [
  {
    id: 1,
    title: 'JavaScript ( ES5, ES6 ) - куда же без него',
    image: '/icons/javascript-original.svg',
    alt: 'js',
    sort: 1
  },
  {
    id: 2,
    title: 'React + Redux/Mobx или Vue - для основы приложений',
    image: '/icons/react-original.svg',
    alt: 'react',
    sort: 2
  },
  {
    id: 3,
    title: 'TS/Flow - для статической типизации',
    image: '/icons/typescript-original.svg',
    alt: 'TS/flow',
    sort: 3
  },
  {
    id: 4,
    title: 'Node.js + Express для создание серверной части приложения',
    image: '/icons/nodejs-original.svg',
    alt: 'node',
    sort: 4
  },
  {
    id: 5,
    title: 'CSS/SASS/Less для оформления стилей',
    image: '/icons/css3-original.svg',
    alt: 'sass',
    sort: 5
  },
  {
    id: 6,
    title: 'Или styled-components для оформления стилей',
    image: '/images/styled.jpg',
    alt: 'styled components',
    sort: 6
  },
  {
    id: 7,
    title: 'Mocha и т. п. для тестирования кода',
    image: '/icons/mocha-plain.svg',
    alt: 'mocha',
    sort: 7
  }
];
