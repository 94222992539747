import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BaseLayout from '../../layouts/BaseLayout';
import Container from '../../layouts/Container';

class BlogPage extends Component {
  render() {
    return (
        <BaseLayout>
          <section className="section">
            <Container>
              <br/>
              <p>
                Скоро открытие...
              </p>
            </Container>
          </section>
        </BaseLayout>
    );
  }
}

BlogPage.propTypes = {};
BlogPage.defaultProps = {};

export default BlogPage;
