import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const LinkStyled = styled(NavLink)`
  margin-right: auto;
  margin-bottom: 0.5em;
  width: 100%;
  .active {
    background-color: red;
  }
  @media (min-width: 576px) {
    width: auto;
    margin-right: 10px;
  }
`;

const NavigationStyled = styled.nav`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: -0.5em;
  justify-content: ${({center})=> center ? 'center' : 'flex-start'}
`;

class Navigation extends Component {
  render() {
    return (
        <NavigationStyled aria-label="Разделы сайта" role="navigation" {...this.props}>
          <LinkStyled to="/" exact>🦁Кто я?</LinkStyled>
          <LinkStyled to="/projects" exact>🛠️Проекты</LinkStyled>
          <LinkStyled to="/rewards" exact>🏆Достижения</LinkStyled>
          <LinkStyled to="/blog" exact>📓Блог</LinkStyled>
        </NavigationStyled>
    );
  }
}

Navigation.propTypes = {};
Navigation.defaultProps = {
  center: false
};

export default Navigation;
