import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ContactsStyled = styled.ul`
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  list-style: none;
  
  display: flex;
  flex-flow: ${props => props.isRow ? 'row' : 'column'} wrap;
  
  .contacts__item {
    width: 100%;
    margin-bottom: 5px;
    margin-right: 0;
    @media (min-width: 576px) {
      width: auto;
      margin-right: ${props => props.isRow ? '1em' : 'initial'};
    }
  }

  .contacts__item:last-child {
    margin-bottom: 0;
  }
`;

class Contacts extends Component {
  render() {
    return (
        <Fragment>
          <div className='header'>
            <h2 className='f2 lh-title'>Контакты</h2>
          </div>
          <ContactsStyled id="contacts" style={{marginBottom: '0.5em'}}>
            <li className="contacts__item">
              <span>💬</span>&nbsp;<a className="link" href="https://t.me/mullagaliev"
                                 target="_blank">Телеграм</a>
            </li>
            <li className="contacts__item">
              <span>☎️</span>&nbsp;<a className="link" href="tel:89995481920">8(999)548-19-20</a>
            </li>
            <li className="contacts__item">
              <span>✉️️</span>&nbsp;<a className="link"
                                  href="mailto:andrew@mullagaliev.com">andrew@mullagaliev.com</a>
            </li>
          </ContactsStyled>
          <ContactsStyled isRow>
            <li className="contacts__item">
              <a className="link link--www" href="https://twitter.com/mull5ev"
                 target="_blank">Твиттер</a>
            </li>
            <li className="contacts__item">
              <a className="link link--www" href="https://github.com/mullagaliev"
                 target="_blank">Гитхаб</a>
            </li>
            <li className="contacts__item">
              <a className="link link--www" href="https://www.codewars.com/users/mullagaliev"
                 target="_blank">Codewars</a>
            </li>
          </ContactsStyled>
        </Fragment>
    );
  }
}

Contacts.propTypes = {};
Contacts.defaultProps = {};

export default Contacts;
