import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class RewardsList extends Component {
  render() {
    return (
        <Fragment>
          <h3><a name="hackathons"></a>Стал призером/победителем на 6-х хакатонах:</h3>
          <ol>
            <li>
              <a href="http://adhack.muzis.ru/" target="_blank">Hackathon по технологиям в
                рекламе и маркетинге (г. Санкт-петербург)</a> 🥇
            </li>
            <li>
              <a href="https://psbattle.ru/" target="_blank">Хакатон PSB Samara Battle (г.
                Самара)</a>
              🥇
            </li>
            <li>
              <a href="http://tealtechmedhack.sci-guide.com/" target="_blank">ТИЛТЕХ
                МЕДХАК (г. Москва)</a> 🥇
            </li>
            <li>
              <a href="http://tealtechmedhack.sci-guide.com/" target="_blank">ТИЛТЕХ
                МЕДХАК (г. Санкт-петербург)</a> 🥉
            </li>
            <li>
              <a href="http://hackuniversity.ru/" target="_blank">HACKUNIVERSITY
                2018 (г. Санкт-петербург)</a> 🥈
            </li>
            <li>
              <a href="http://actum.online/events/actumdigitalhack/" target="_blank">ActumDigitalHack
                (г. Санкт-петербург)</a> 🥈
            </li>
          </ol>
          <h3>Стал финалистом в 2-х хакатонах:</h3>
          <ol>
            <li>
              <a href="https://hack.evotor.ru/" target="_blank">HACKING MAN (г. Москва)</a> 🔥🔥🔥
            </li>
            <li>
              <a href="https://hackathon2025.ru/" target="_blank">HACKTHON 2025 (г.
                Москва)</a> 🤮
            </li>
          </ol>
        </Fragment>
    );
  }
}

RewardsList.propTypes = {};
RewardsList.defaultProps = {};

export default RewardsList;
