import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '../Container';
import Navigation from '../Navigation';

const HeaderStyled = styled.header`
  flex: 0 0 auto;
  padding: 10px 0;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  img{
    margin: 0;
    margin-top: 1rem;
  }
  @media(min-width: 580px){
    flex-flow: row wrap;
    img{
      margin-right: 1rem;
      margin-top: 1rem;
    }
  }
`;

class Header extends Component {
  render() {
    return (
        <HeaderStyled>
          <Container>
            <LogoContainer>
              <img
                  src="/images/square_avatar.jpg"
                  className="br-100 h3 w3 dib" alt="avatar"/>
              <h1 className="andrew"><span className="name">Андрей</span>&ensp;Муллагалиев</h1>
            </LogoContainer>
            <Navigation/>
          </Container>
        </HeaderStyled>
    );
  }
}

Header.propTypes = {};
Header.defaultProps = {};

export default Header;
