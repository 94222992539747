import { teams } from '../../Teams/data';

export const list = [
  // 2016 - 2017
  {
    id: 1,
    title: 'Сайт туропреатора «Планета Тур»',
    description: '',
    preview: '/images/planett.png',
    detail: '/images/planett.png',
    textPreview: '',
    site: 'http://www.planett.ru',
    year: '2016',
    location: 'г. Тюмень',
    team: teams["GAGARIN"],
    project: {
      title: "Сайт туропреатора «Планета Тур»",
      description: ""
    },
    stack: ["PHP", "MySQL", "JADE", "SASS", "GULP", "JQuery"],
    tasks: ["Создание структуры БД", "Настройка административной части сайта", "Верстка по макетам", "Интеграция с сервисом Tourclient"],
    tags: ["планета тур", "planett", "туропреатор", "проект"],
    sort: 1,
    column: 1
  },
  {
    id: 2,
    title: 'Аэропорт города Сургут',
    description: '',
    preview: '/images/aero-surgut.png',
    detail: '/images/aero-surgut.png',
    textPreview: '',
    site: 'http://www.airport-surgut.ru/',
    year: '2016',
    location: 'г. Тюмень',
    team: teams["GAGARIN"],
    project: {
      title: "«АЭРОПОРТ СУРГУТ»",
      description: ""
    },
    stack: ["PHP", "MySQL", "AngularJS v1", "JQuery", "SASS", "GULP"],
    tasks: ["Поддержка сайта", "Создание новой верстки сайта (частичное обновление)", "Верстка онлайн-табло", "Создание виджета «Погода»", "Интеграция для работы онлайн-табло"],
    tags: ["планета тур", "planett", "туропреатор", "проект"],
    sort: 2,
    column: 1
  },
  {
    id: 3,
    title: 'сайт Татарской филармония им. Габдуллы Тукая',
    description: '',
    preview: '/images/tkto.png',
    detail: '/images/tkto.png',
    textPreview: '',
    site: 'https://tatfil.ru/',
    year: '2016',
    location: 'г. Тюмень',
    team: teams["GAGARIN"],
    project: {
      title: "Татарская филармония им. Габдуллы Тукая",
      description: ""
    },
    stack: ["PHP", "PhpDoc", "MySQL", "JQuery", "SASS", "GULP", "MS SQL"],
    // tasks: ["Поддержка сайта", "Создание новой верстки сайта (частичное обновление)", "Верстка онлайн-табло", "Создание виджета «Погода»", "Интеграция для работы онлайн-табло"],
    tags: ["планета тур", "planett", "туропреатор", "проект"],
    sort: 3,
    column: 1,
    disabled: true,
  },
  {
    id: 4,
    title: 'сайт Тюменского концертного-театрального объединения',
    description: '',
    preview: '/images/tkto.png',
    detail: '/images/tkto.png',
    textPreview: '',
    site: 'https://kto72.ru/',
    year: '2017',
    location: 'г. Тюмень',
    team: teams["GAGARIN"],
    project: {
      title: "Тюменское концертное-театральное объединение",
      description: ""
    },
    stack: ["PHP", "PhpDoc", "MS SQL", "MySQL", "Bootstrap", "JQuery", "SASS", "GULP"],
    tasks: ["Разработка нового функционала", "Оптимизировация запросов к базам данных (MS Sql и My Sql)", "Исправление функции синхронизации данных сайта",  "Рефакторинг и документирование", "Правка верстки"],
    tags: ["планета тур", "planett", "туропреатор", "проект"],
    sort: 4,
    column: 2,
  },
  {
    id: 5,
    title: 'Модуль для продажи билетов на мероприятия филармоний',
    description: '',
    preview: '/images/kto_tickets.png',
    detail: '/images/kto_module.png',
    textPreview: 'Модуль служит для работы с Кассовой системой (Далее КС) - система для хранения информации о мероприятиях, абонементах, пользователях и заказах (С аналогичной системой работает сервис kassir.ru для продажи билетов на мероприятия филармоний и им подобным). Модуль используется на двух сайтах (≈60.000 - 80.000 пользователей).',
    site: 'https://kto72.ru/',
    year: '2017',
    location: 'г. Тюмень',
    team: teams["GAGARIN"],
    project: {
      title: "Модуль для продажи билетов на меропрятия филармоний",
      description: ""
    },
    stack: ["PHP", "PhpDoc", "MS SQL", "MySQL"],
    tasks: ["Исправление, поддержка и расширение моудля", "Создание функционала «Личный Филармонический Абонемент»", "Создание функционала «Электронный билет»", "Оптимизация, рефакторинг и документирование"],
    tags: ["планета тур", "planett", "туропреатор", "проект"],
    sort: 5,
    column: 2,
  },
  // 2018
  {
    id: 6,
    title: 'Ресторан счастливой кухни «Happy»',
    description: '',
    preview: '/images/happy.png',
    detail: '/images/happy.png',
    textPreview: '',
    site: 'http://happyeda.ru/',
    year: '2018',
    location: 'г. Санкт-Петербург',
    project: {
      title: "Ресторан счастливой кухни «Happy»",
      description: ""
    },
    stack: ["PHP", "MySQL", "Vue.js"],
    tasks: ["Доработка шаблона", "Задачи по SEO (помощь SEO специалистам)", "Поддержка/Настройка сайта"],
    tags: ["планета тур", "planett", "туропреатор", "проект"],
    sort: 6,
    column: 2,
  },
  {
    id: 7,
    title: 'Сайт для реализации стройматериалов',
    description: '',
    preview: '/images/stroymat.png',
    detail: '/images/stroymat.png',
    textPreview: '',
    site: 'http://stroymat-incorp.ru/',
    year: '2018',
    location: 'г. Санкт-Петербург',
    project: {
      title: "Сайт для реализации стройматериалов",
      description: ""
    },
    stack: ["PHP", "MS SQL", "MySQL", "jQuery"],
    tasks: ["Установка/Настройка", "Доработка шаблона"],
    tags: ["планета тур", "planett", "туропреатор", "проект"],
    sort: 7,
    column: 2,
  },
  {
    id: 8,
    title: 'Металлургическая Корпорация',
    description: '',
    preview: '/images/metal.png',
    detail: '/images/metal.png',
    textPreview: '',
    site: 'http://metalincorp.ru/',
    year: '2018',
    location: 'г. Санкт-Петербург',
    project: {
      title: "Металлургическая Корпорация",
      description: ""
    },
    stack: ["PHP", "MS SQL", "MySQL", "Bootstrap"],
    tasks: ["Настройка", "Доработка верстки каталога", "Оптимизация работы каталога с ≈500.000 товарных позиций (почти Highload)"],
    tags: ["планета тур", "planett", "туропреатор", "проект"],
    sort: 8,
    column: 2,
  },
  {
    id: 9,
    title: 'Интернет-магазин PHONE72',
    description: '',
    preview: '/images/phone72.png',
    detail: '/images/phone72.png',
    textPreview: '',
    site: 'http://tyumen.new.phone72.ru/',
    year: '2018',
    location: 'г. Санкт-Петербург',
    project: {
      title: "Интернет-магазин PHONE72",
      description: ""
    },
    stack: ["PHP", "MS SQL", "MySQL", "Semantic UI", "SASS", "GULP", "JADE", "JQuery"],
    tasks: ["Верстка страниц по макетам", "Адаптивная верстка", "Программирование бизнес логики", "Доработка функионала", "Оптимизация работы страниц"],
    tags: ["планета тур", "planett", "туропреатор", "проект"],
    sort: 9,
    column: 1,
  },
  {
    id: 10,
    title: 'Клиент hackathons.space',
    description: '',
    preview: '/images/hack_client.png',
    detail: '/images/hack_client.png',
    textPreview: '',
    site: 'https://hackathons.space/',
    year: '2018',
    location: 'г. Санкт-Петербург',
    project: {
      title: "Клиент hackathons.space",
      description: ""
    },
    stack: ["React", "Redux", "Webpack", "styled-components", "SASS"],
    tasks: ["Верстка страниц по макетам", "Создание библиотеки UI компонентов", "Программирование интерфейса"],
    tags: ["планета тур", "planett", "туропреатор", "проект"],
    sort: 2,
    column: 1,
  },
  {
    id: 11,
    title: 'Админ. панель hackathons.space',
    description: '',
    preview: '/images/hack_manage.png',
    detail: '/images/hack_manage.png',
    textPreview: '',
    site: 'https://manage.hackathons.space',
    year: '2018',
    location: 'г. Санкт-Петербург',
    project: {
      title: "Админ. панель hackathons.space",
      description: ""
    },
    stack: ["React", "Redux", "Webpack", "styled-components", "SASS"],
    tasks: ["Верстка страниц по макетам", "Создание библиотеки UI компонентов", "Программирование интерфейса"],
    tags: ["планета тур", "planett", "туропреатор", "проект"],
    sort: 1,
    column: 1,
  }
];