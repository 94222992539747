import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import { ThemeSwitcher } from '../../components/ThemeSwitcher';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Main = styled.main`
  flex: 1 0 auto;
`;

const WhiteTheme = createGlobalStyle`
  body {
    // --main-brand-color: #ff0f00;
    --bg-color: white;
    --main-text-color: black;
  }
`;

function getCurrentTheme() {
  const themes = ['light', 'black'];
  const theme = localStorage.getItem('theme-style');
  if(!themes.includes(theme)){
    return 'black';
  }
  return theme;
}

class BaseLayout extends Component {
  state = {
    theme: getCurrentTheme()
  };

  onToggleTheme = () => {
    const theme = this.state.theme === 'black' ? 'light' : 'black';
    this.setState({ theme });
    localStorage.setItem('theme-style', theme);
  };

  render() {
    const { theme } = this.state;
    return (
        <Wrapper>
          {theme === 'light' && <WhiteTheme/>}
          <ThemeSwitcher onToggleTheme={this.onToggleTheme} theme={theme}/>
          <Header/>
          <Main>
            {this.props.children}
          </Main>
          <Footer/>
        </Wrapper>
    );
  }
}

BaseLayout.propTypes = {};
BaseLayout.defaultProps = {
  header: null,
  children: (
      <p>
        Hello i am empty page!
      </p>
  )
};

export default BaseLayout;
