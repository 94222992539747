import React, { Component } from 'react';
import Container from '../../layouts/Container';
import Navigation from '../../layouts/Navigation';

export const Page404 = ({ location }) => (
    <div>
      <section className="section">
          <section className="vh-100 baskerville">
            <header className="tc ph5 lh-copy">
              <h1 className="f1 f-headline-l code mb3 fw9 dib tracked-tight light-purple">404</h1>
              <h2 className="tc f1-l fw1">Страница не найдена :(</h2>
            </header>
            <p className="fw1 i tc mt4 mt5-l f4 f3-l">Возможно, Вы сможете найти то что искали ниже?</p>
            <div className="list tc pl0 w-100 mt5">
              <Container>
                <Navigation center/>
              </Container>
            </div>
          </section>
      </section>
    </div>
);

export default Page404;
