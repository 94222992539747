import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaseLayout from '../../layouts/BaseLayout';
import Container from '../../layouts/Container';
import { ProjectsList } from '../../components/Projects';
import { list } from '../../components/Projects/data';

class ProjectsListPage extends Component {
  render() {
    return (
        <BaseLayout>
          <section className="section">
            <Container>
              <div className='header'>
                <h2 className='f2 lh-title'>2018</h2>
                <span className='header__subtitle'>Фриланс</span>
              </div>
              <ProjectsList list={list.filter(item => item.year === '2018')}/>
              <div className='header'>
                <h2 className='f2 lh-title'>2018 (Прочее)</h2>
                <span
                    className='header__subtitle'><i>(Раздел находится в разработке...)</i></span>
              </div>
              <ul>
                <li>Разрабатывал API для iOS приложения стриминга курсов;
                </li>
                <li>Сделал SPA и API для Админ. панель для управления пользователями
                  и&nbsp;их&nbsp;денежными
                  средствами;
                </li>
                <li>Сделал Админ. панель и&nbsp;внутренний чат для очень известной компании,
                  которую нельзя называть;
                </li>
              </ul>
              <div className='header'>
                <h2 className='f2 lh-title'>2016 - 2017</h2>
                <span className='header__subtitle'>КОМАНДА «GAGARIN ONLINE»</span>
              </div>
              <ProjectsList
                  list={list.filter(item => item.year === '2016' || item.year === '2017')}/>
            </Container>
          </section>
        </BaseLayout>
    );
  }
}

ProjectsListPage.propTypes = {};
ProjectsListPage.defaultProps = {};

export default ProjectsListPage;
