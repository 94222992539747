import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaseLayout from '../../layouts/BaseLayout';
import Container from '../../layouts/Container';
import { Redirect } from 'react-router-dom';
import { HackathonsDetail } from '../../components/Hackathons';
import { list } from '../../components/Hackathons/data';

class HackathonsDetailPage extends Component {
  render() {
    const { id } = this.props;
    const item = list.find(item => item.id === +id);
    if (item)
      return (
          <BaseLayout>
            <section className="section">
              {/*<Container>*/}
              <HackathonsDetail item={item}/>
              {/*</Container>*/}
            </section>
          </BaseLayout>
      );
    return <Redirect to='/404'/>;
  }
}

HackathonsDetailPage.propTypes = {};
HackathonsDetailPage.defaultProps = {};

export default HackathonsDetailPage;
