import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from "styled-components";
import { getPlaceIcon } from '../../utils';

const CardAction = styled.div`
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  opacity: 0;
  padding: 15px;
  font-size: 1rem;
  background-color: var(--main-brand-color);
  transition: all 0.5s ease-in-out;
`;

const Card = styled(NavLink)`
  overflow: hidden;
  display: block;
  position: relative;
  background: url(${({ preview }) => preview}) no-repeat center center fixed; 
  background-color: var(--medium-color);
  background-size: cover;
  margin-bottom: 15px;

  @media(min-width: 864px){
    margin: 15px;
    margin-bottom: 30px;
  }
  &:hover{
    ${CardAction}{
      left: 0;
      opacity: 1;
    }
  }

`;

const CardPlace = styled.div`
  &:before{
    content: ${({ placeIcon }) => placeIcon ? `"${placeIcon}"` : 'initital'};
    position: absolute;
    top:0;
    right: 0;
    padding: 15px;
    font-size: calc(${({place})=> Math.abs(4-place)} * 1rem);
    background-color: var(--main-brand-color);
  }
`;



export class HackathonsItem extends Component {
  render() {
    const { data } = this.props;
    return (
        <Card to={`/hackathons/${data.id}`}
              className="grow db no-underline black"
              {...this.props}>
          <img className="db w-100"
               src={data.preview}
               alt={data.title}/>
          <CardPlace placeIcon={getPlaceIcon(data.place)} place={data.place}/>
          <CardAction>Подробнее</CardAction>
        </Card>
    );
  }
}

HackathonsItem.propTypes = {};
HackathonsItem.defaultProps = {
  data: null
};

export default HackathonsItem;
